import React from 'react';
//import image from '../public/img/obicna-kafa.png';

const Menu = () => {
    return (
        <div className ="container">
                        <div className="logo">
                <img src={require("../public/img/galerija-logo2.png")} alt="" className="menu-item-image" />
                        </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    TOPLI NAPITCI
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                    <img src={require("../public/img/obicna-kafa.png")} alt="" className ="menu-item-image" />
                                        <div className ="menu-item-text">
                                            <h3 className ="menu-item-heading">
                                                <span className ="menu-item-name">Kafa</span>
                                                <span className ="menu-item-price">2.00 KM</span>
                                            </h3>
                                            <p className ="menu-item-description"></p>
                                        </div>
                                    </div>
                                    <div className ="menu-item">
                                        <img src={ require ("../public/img/kafa-sa-slagom.png")} alt="" className ="menu-item-image" />
                                        <div className ="menu-item-text">
                                            <h3 className ="menu-item-heading">
                                                <span className ="menu-item-name">Kafa sa Šlagom</span>
                                                <span className ="menu-item-price">2.50 KM</span>
                                            </h3>
                                            <p className ="menu-item-description">

                                            </p>
                                        </div>
                                    </div>
                                    <div className ="menu-item">
                                        <img src={require("../public/img/xelecto1.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Kafa Xelecto</span>
                                                    <span className ="menu-item-price">2.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Dolce, Zenzero Cannella, Nocciolato, Deciso, India, Guatemala,
                                                    Decaffeinato, Cioccolato Arancia.
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public/img/dolcezza-caj2.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Dolcezza Čaj</span>
                                                    <span className ="menu-item-price">2.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Kamilica, Zeleni čaj, Ananas, Menta, Crni, Voćni, Limun, Kineski, Višnja, Kruška, Narandža, Pina Colada, Berry, Engleski čaj.
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public/img/topla-cokolada.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Topla Čokolada</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Čokolada, Crna Čokolada, Jagoda, Tiramisu, Kokos, Bijela Čokolada.
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/cappuccino2.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Cappuccino</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Čokolada, Vanilija, Irish.
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/nescafe3.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Nescafe</span>
                                                    <span className ="menu-item-price">2.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/tea.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Čaj</span>
                                                    <span className ="menu-item-price">2.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Zeleni, Voćni, Menta, Uvin, Kamilica, Jagoda Vanilija, Jabuka Cimet, Brusnica.
                                                </p>
                                            </div>
                                    </div>

                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    CAFFE KOKTELI
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/afoggatto.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Caffe Affogato</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/wiener2.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Caffe wiener</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/latte-macchiato.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Latte Macchiato</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/wiener2.webp")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Ledena Kafa</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    Gazirani Sokovi
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/coca-cola.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Coca Cola</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/coca-cola-zero2.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Coca Cola Zero</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/fanta3.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Fanta</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/bitter-lemon.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Schweppes</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Bitter Lemon 0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/tonic.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Schweppes</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Tonic 0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/sprite.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Sprite</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/cockta.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Cockta</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.275l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/orangina.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Orangina</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/ski.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Ski</span>
                                                    <span className ="menu-item-price">2.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Mango 0.25l, Naranča 0.25l
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    Ostala Bezalkoholna Pića
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/gusti-breskva.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Gusti Sok</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Jagoda, Jabuka, Ribizla, Naranča, Ananas, Višnja 0,20l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/naranca-juicy-vita.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Juicy Vita</span>
                                                    <span className ="menu-item-price">2.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Narandža, Limun 0,20l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/Fuze-tea.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Ledeni čaj</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/sensation.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Sensation</span>
                                                    <span className ="menu-item-price">2.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/kuca-prirode-aronia.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Kuća prirode</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Aronia 0.20l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/prirodna-naranca.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Prirodna Narandža</span>
                                                    <span className ="menu-item-price">5.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/limunada.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Limunada</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/prirodni-mix.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Prirodni Mix</span>
                                                    <span className ="menu-item-price">6.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    VODA
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/jana.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Jana</span>
                                                    <span className ="menu-item-price">2.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/sarajevski-kiseljak.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Mineralna Voda</span>
                                                    <span className ="menu-item-price">2.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/Jana-Limun-Limeta.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Jana Voda sa okusom</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Limeta, Limun, Narandža
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    TORTE, KOLAČI, SLADOLED
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/nutella.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Nutella torta</span>
                                                    <span className ="menu-item-price">4.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/pistacija-torta.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Pistachio torta</span>
                                                    <span className ="menu-item-price">4.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/cheesecake.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Cheesecake</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">

                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/sladoled.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Sladoled</span>
                                                    <span className ="menu-item-price">2.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Kugla
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    VINO
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/bijelo-nuic-vrhunsko.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Bijelo Nuić Vrhunsko</span>
                                                    <span className ="menu-item-price">30.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.75l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/crno-nuic-vrhunsko.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Crno Nuić Vrhunsko</span>
                                                    <span className ="menu-item-price">30.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.75l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/bijelo-0.2.jpg.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Bijelo vino</span>
                                                    <span className ="menu-item-price">6.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.20l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/crno-0.2.jpg.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Crno vino</span>
                                                    <span className ="menu-item-price">7.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.20l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/vino-casa.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Vino čaša</span>
                                                    <span className ="menu-item-price">2.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    crno, bijelo 0.10l
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    PIVO
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/heineken.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Heineken</span>
                                                    <span className ="menu-item-price">4.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/heineken0.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Heineken 0%</span>
                                                    <span className ="menu-item-price">4.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0% Alcohol free, 0.33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/heineken-glass.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Heineken Točeno 0.5</span>
                                                    <span className ="menu-item-price">5.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.50l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/heineken-glass-0.2.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Heineken Točeno 0.2</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,20l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/toceno-velik.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Točeno pivo 0.5</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.50l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/toceno-malo.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Točeno pivo 0.2</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/lagunitas.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Craft Pivo Lagunitas</span>
                                                    <span className ="menu-item-price">6.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/union.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Union</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/karlovacko-crno.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Karlovačko Crno</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/karlovacko-radler.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Karlovačko Radler</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/lasko.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Laško</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/lasko-malt.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Laško Malt</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/krusovice.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Krušovice</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,50l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/Zajecarsko.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Zaječarsko</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/somersby.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Somersby</span>
                                                    <span className ="menu-item-price">5.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0,33l
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    ALKOHOLNA PIĆA
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/Johnnie Walker.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Johnnie Walker</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/jack-daniels.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Jack Daniels</span>
                                                    <span className ="menu-item-price">5.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/Jameson.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Jameson</span>
                                                    <span className ="menu-item-price">5.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/vodka.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Vodka</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/jagermeiseter2.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Jagermeister</span>
                                                    <span className ="menu-item-price">3.50 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/vlahovac.webp")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Vlahovac Maraska</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/pelin.webp")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Pelin Maraska</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/amarena.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Amarena Višnjevac</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/viljamovka.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Viljamovka rakija</span>
                                                    <span className ="menu-item-price">4.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.03l
                                                </p>
                                            </div>
                                    </div>
                                    <div className ="menu-item">
                                            <img src={require("../public//img/sljiva.webp")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Rakija</span>
                                                    <span className ="menu-item-price">3.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    Šljiva, Jabuka 0.03l
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="menu">
                                <h2 className ="menu-group-heading">
                                    ENERGETSKA PIĆA
                                </h2>
                                <div className ="menu-group">
                                    <div className ="menu-item">
                                            <img src={require("../public//img/red-bull.png")} alt="" className ="menu-item-image" />
                                            <div className ="menu-item-text">
                                                <h3 className ="menu-item-heading">
                                                    <span className ="menu-item-name">Red Bull</span>
                                                    <span className ="menu-item-price">6.00 KM</span>
                                                </h3>
                                                <p className ="menu-item-description">
                                                    0.25l
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <p>U skladu sa članom 39. Zakona o porezu na promet proizvoda i
                            usluga dužni ste uzeti račun sa kase za svaku pojedinačnu
                            kupovinu robe i usluga. Račun ste dužni čuvati kod sebe, sve do
                            izlaska iz našeg objekta. Ukoliko ne dobijete račun niste dužni ni platiti.
                            Prodaja alkoholnih pića osobama mlađim od 18 godina je
                            zakonom zabranjena.
                            Knjiga žalbi se nalazi u šanku.</p>
        </div>
    );
};

export default Menu;